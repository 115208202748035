import React from 'react'
import { useSelector } from 'react-redux';
import { EditPage } from '@equipedigital/ed-cms/components';
//import { fetchCasino, editCasino, uploadCasino } from '../api/casinos'
import * as contentsApi from '../api/content'

const EditContent = ({edit}) => {
  const translations = useSelector(state => state.translations.translations['system-contents'])
  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'content',
      type: 'tiny',
      title: translations['edit-content']
    }
  ]
  return (
    // <EditPage selectedData={selectedData} apiFunctions={casinoApi} fetchFunction={fetchCasino} editFunction={editCasino} uploadFunction={uploadCasino} edit={true} url='casinos'/>
    <EditPage
      title={translations['editpage-title']}
      subtitle={translations['editpage-subtitle']}
      selectedData={selectedData} 
      fetchFunction={contentsApi.fetchItem} 
      editFunction={contentsApi.editItem} 
      edit={edit} 
      url='contents'
    />
  )
}

export default EditContent
