import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import { MainLayout } from '@equipedigital/ed-cms/layouts'

import IndexPage from '../pages/Index'
import NotFoundPage from '../pages/NotFound'

import ContentsPage from '../pages/Contents'
import EditContentPage from '../pages/EditContent'

import Portfolios from '../pages/Portfolios'
import EditPortfolio from '../pages/EditPortfolio'

import { 
  UsersPage,
  EditUserPage,
  EditMetatagsPage,
  EditConfigPage,
  EditSocialMidiaPage,
  TranslationsPage,
  EditTranslationsPage,
  Contatos,
  ViewContatosPage
} from '@equipedigital/ed-cms/pages'

import { startSetLang, startFetchTranslations } from '@equipedigital/ed-cms/actions'

import { useSelector } from 'react-redux'

import '@equipedigital/ed-cms/layouts/main.css'
import '@equipedigital/ed-cms/pages/main.css'
import '@equipedigital/ed-cms/components/main.css'

const SystemRouter = () => {
  const dispatch = useDispatch()
  const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:3000/'
  const full_url = url + 'metatags/'
  const full_url_config = url + 'config/'
  const full_url_socialmidia = url + 'socialmidia/'
  const { loggedIn, user } = useSelector(state => state.auth)
  const translations = useSelector(state => state.translations.translations.sidebar)

  useEffect(() => {
    if (user && user.preferences) {
      dispatch(startSetLang(user.preferences.lang))
      dispatch(startFetchTranslations(process.env.REACT_APP_API_URL))

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const navigation = [
    
    {
      link: '/contents',
      title: translations.site_simple_content
    },
    {
      link: '/portfolios',
      title: translations.site_portfolios
    },
  ]
  return (loggedIn ?
    <Router>
      <MainLayout navigation={navigation}>
        <Switch>
          <Route path="/" component={IndexPage} exact />


          <Route
            path="/edit/contents/:id"
            render={(props) => (
              <EditContentPage {...props} edit={true} />
            )}
          />
          <Route path="/add/contents" component={EditContentPage} />
          <Route path="/contents" component={ContentsPage} />
              
          <Route path="/portfolios" component={Portfolios} />
          <Route path="/add/portfolios" component={EditPortfolio} />
          <Route
            path="/edit/portfolios/:id"
            render={(props) => (
              <EditPortfolio {...props} edit={true} />
            )}
          />
        
          <Route path="/edit/metatags">
            <EditMetatagsPage full_url={full_url} />
          </Route>
          <Route path="/edit/config">
            <EditConfigPage full_url={full_url_config} />
          </Route>
          <Route path="/edit/socialmidia">
            <EditSocialMidiaPage full_url={full_url_socialmidia} />
          </Route>

          <Route path="/edit/users/:id">
            <EditUserPage api_url={url} edit={true} />
          </Route>
          <Route path="/add/users">
            <EditUserPage api_url={url} />
          </Route>
          <Route path="/users">
            <UsersPage api_url={url} />
          </Route>
          

          <Route path="/edit/contatos/:id">
            <ViewContatosPage api_url={url} edit={true} />
          </Route>
          <Route path="/contatos">
            <Contatos api_url={url} />
          </Route>

          <Route path="/edit/translations/:id" >
            <EditTranslationsPage api_url={url} />
          </Route>
          <Route path="/translations">
            <TranslationsPage api_url={url} />
          </Route>
          <Route component={NotFoundPage} />
        </Switch>
      </MainLayout>
    </Router>
    : <Redirect to='/login' />)
}

export default SystemRouter
