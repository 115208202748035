import React, {useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { BigCard, Tabela, Header } from '@equipedigital/ed-cms/components'
import * as portfoliosApi from '../api/portfolios'
import { useSelector } from 'react-redux'

const Portfolios = () => {
  const translations = useSelector(state => state.translations.translations)
  const portfoliosTranslations = translations['system-portfolios']

  useEffect(() => {
  }, [])

  const columns = [
    {
      title: portfoliosTranslations['manage-image'],
      field: '_id',
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 66,
      },
      render: rowData =>
        <img
          className="portfolio-img"
          src={
            portfoliosApi.getImgPortfolio(rowData._id)
          }
          alt="" />
    },
    {
      title: portfoliosTranslations['manage-title'],
      field: "titulo",
      sorting: true
    }
  ];

  // const options = {
  //   filtering: true,
  //   actionsColumnIndex: -1,
  //   paging: false
  // }

  return (
    <React.Fragment>
      <Header title={portfoliosTranslations['title']} subtitle={portfoliosTranslations['subtitle']}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            url={'portfolios'}
            columns={columns}
            fetchFunction={portfoliosApi.fetchItems}
            removeFunction={portfoliosApi.removeItem}
            statusFunction={portfoliosApi.statusItem}
            enableSearch
            enableDatas
          />
        </BigCard>
      </Container>
    </React.Fragment>
  )
}

export default Portfolios
