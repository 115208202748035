import React, { useEffect, useState } from 'react'
import { EditPage } from '@equipedigital/ed-cms/components'
// import { EditPage } from '../components/EditPage'
import * as portfolioApi from '../api/portfolios'
import { useSelector } from 'react-redux'

const EditPortfolio = ({ edit }) => {
  const translations = useSelector(state => state.translations.translations)
  const portfoliosTranslations = translations['system-portfolios']

  useEffect(() => {}, [])
  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id'
    },
    {
      name: 'titulo',
      type: 'text',
      title: portfoliosTranslations['edit-title'],
      validation: {
        required: true
      },
      options: {
        maxLength: '50'
      },
      tooltip: {
        text: portfoliosTranslations['edit-title-p']
      }
    },
    {
      name: 'img',
      type: 'dragdrop',
      title: portfoliosTranslations['edit-image'],
      options: {
        maxFiles: 1
      },
      validation: {
        required: true
      },
    },
    {
      name: 'galeria',
      type: 'dragdrop',
      title: portfoliosTranslations['edit-galeria'],
      options: {
        customRemoveImgFromArray: (id, imgid) => portfolioApi.removeImgGaleria(id, imgid)
      }
    },
    {
      name: 'url',
      type: 'text',
      title: portfoliosTranslations['edit-url'],
      subtitle: portfoliosTranslations['edit-url-s'],
      validation: {
        required: true
      },
      tooltip: {
        text: portfoliosTranslations['edit-url-p']
      }
    }
  ]
  return (
    <EditPage
      title={edit ? portfoliosTranslations['editpage-title'] : portfoliosTranslations['addpage-title']}
      subtitle={edit ? portfoliosTranslations['editpage-subtitle'] : portfoliosTranslations['addpage-subtitle']}
      selectedData={selectedData} 
      addFunction={portfolioApi.addItem}
      fetchFunction={portfolioApi.fetchItem}
      editFunction={portfolioApi.editItem}
      uploadFunction={portfolioApi.uploadPortfolio}
      edit={edit}
      url='portfolios'
    />
  )
}

export default EditPortfolio
